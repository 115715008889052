// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Heading.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Heading.tsx");
}
// REMIX HMR END

import clsx from 'clsx';
import styles from './Heading.module.css';
export function Heading({
  as,
  level,
  children,
  className = '',
  ...props
}) {
  const Component = as || `h${level}`;
  return <Component className={clsx(styles[`h${level}`], className)} {...props}>
      {children}
    </Component>;
}
_c = Heading;
var _c;
$RefreshReg$(_c, "Heading");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;