// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Button.tsx");
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import clsx from 'clsx';
import styles from './Button.module.css';
function LoadingSpinner() {
  return <div className={styles.spinner}>
      <svg viewBox="0 0 28 28" fill="none">
        <path d="m1.5,14.33333a12,12 0 0 0 12,12" stroke="#4A91F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="m13.5,1.5a12,12 0 0 0 -12,12" stroke="#00CFDA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="m14.5,1.5a12,12 0 0 1 12,12" stroke="#4A91F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>;
}
_c = LoadingSpinner;
export function Button({
  as = 'button',
  className,
  to,
  onClick,
  block = false,
  invert = false,
  loading,
  children,
  ...props
}) {
  const propsForChild = {
    className: clsx(styles.button, block && styles.block, invert && styles.invert, className),
    children,
    ...props
  };
  if (as === 'a') {
    if (!to) {
      throw new Error('`to` prop wasn\'t specified for <Button as="a">');
    }
    return <Link to={to} {...propsForChild} />;
  } else if (as === 'button') {
    const Element = 'button';
    return <Element onClick={onClick} {...propsForChild}>
        {children}

        {loading ? <LoadingSpinner /> : null}
      </Element>;
  } else {
    return null;
  }
}
_c2 = Button;
var _c, _c2;
$RefreshReg$(_c, "LoadingSpinner");
$RefreshReg$(_c2, "Button");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;