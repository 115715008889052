// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Text.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Text.tsx");
}
// REMIX HMR END

import clsx from 'clsx';
import { createElement } from 'react';
import styles from './Text.module.css';
export function Text({
  as = 'p',
  size = 'md',
  weight = 400,
  className = '',
  ...props
}) {
  return createElement(as, {
    className: clsx(styles[size], styles[`w${weight}`], className),
    ...props
  });
}
_c = Text;
var _c;
$RefreshReg$(_c, "Text");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;